var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function ({ on, attrs }) {
              return [
                _c(
                  "div",
                  _vm._g(
                    _vm._b({ staticClass: "w-100" }, "div", attrs, false),
                    on
                  ),
                  [_vm._t("default")],
                  2
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("animation")]),
              _vm._v(" Clone " + _vm._s(_vm.application.long_id) + " "),
            ],
            1
          ),
          _c("v-card-text", { staticClass: "pt-3" }, [
            _vm._v(
              " Do you want to make a clone of your application in this instance, with all the installed libraries copied to the new application? The operation will run in the background and can take a while depending on the size of the application's library. "
            ),
          ]),
          _vm.error
            ? _c(
                "v-card-text",
                [
                  _c(
                    "v-alert",
                    {
                      staticClass: "mb-0",
                      attrs: { color: "error", text: "", icon: "warning" },
                    },
                    [
                      _vm._v(
                        "Something went wrong during cloning. please try again later."
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "secondary",
                    text: "",
                    disabled: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.dialog = false
                    },
                  },
                },
                [_vm._v("Cancel")]
              ),
              _vm.success
                ? _c(
                    "v-btn",
                    { attrs: { color: "success", text: "" } },
                    [_c("v-icon", [_vm._v("check")])],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        loading: _vm.loading,
                      },
                      on: { click: _vm.clone },
                    },
                    [_vm._v("Clone")]
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }